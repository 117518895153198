<template>
  <div class="container mt-3 mb-5">
    <nav aria-label="breadcrumb" class="py-2">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/" class="text-black"
            ><i class="fas fa-home"></i
          ></router-link>
        </li>
        <li class="breadcrumb-item" style="cursor: pointer">
          <router-link to="/" class="text-black">Artikel</router-link>
        </li>
        <li class="breadcrumb-item active">
          {{ article != null ? article.attributes.title : "" }}
        </li>
      </ol>
    </nav>
    <div class="p-3 bg-white rounded">
      <div
        class="holder-img"
        v-if="getImgArticle(article) != null"
        :style="`background-image: url(${getImgArticle(article)})`"
      >
        <!-- <img
          class="img-fluid rounded"
          :src="getImgArticle(article)"
          alt=""
          v-if="getImgArticle(article) != null"
        />
        <img
          class="img-fluid rounded"
          src=""
          alt=""
          v-else
        /> -->
      </div>
      <div
        class="holder-img"
        v-else
        style="background-image: url(../../assets/img/no-img.png)"
      ></div>
      <h4 class="mb-0 mt-5 fw-bold">
        {{ article != null ? article.attributes.title : "" }}
      </h4>
      <small class="extra-small text-muted">{{ getDate(article) }}</small>
      <!-- <p class="mt-4 fw-regular">
        {{ article != null ? article.attributes.body : "" }}
      </p> -->
      <div class="mt-4 fw-regular" v-if="article" v-html="article.attributes.html_content">
      </div>
    </div>
  </div>
</template>
<script>
import API from "./../../utils/API";
import moment from "moment";
export default {
  name: "viewDetailsArticle",
  data() {
    return {
      article: null,
    };
  },
  methods: {
    goto(_path) {
      this.$router.push({
        path: "/",
      });
    },
    getDate(obj) {
      return obj != null
        ? moment(obj.attributes.createdAt).locale('ms').format("LL")
        : null;
    },
    getImgArticle(obj) {
      if (
        obj &&
        obj.attributes &&
        obj.attributes.featured_img_file &&
        obj.attributes.featured_img_file.data &&
        obj.attributes.featured_img_file.data.attributes
      ) {
        return obj.attributes.featured_img_file.data.attributes.url;
      } else {
        return null;
      }
    },
    getArticleDetails(id) {
      if (id != null) {
        API.get(`articles/${id}?populate=%2A`).then((res) => {
          this.article = res.data.data;
        });
      }
    },
  },
  async mounted() {
    await this.getArticleDetails(
      this.$route.params && this.$route.params.id ? this.$route.params.id : null
    );
  },
};
</script>

<style lang="scss" scoped>
.holder-img {
  position: relative;
  display: block;
  // width: 100%;
  height: 350px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;

  img {
    width: 100%;
  }
}

.breadcrumb {
  .breadcrumb-item {
    a {
      color: unset;
      text-decoration: unset;
    }
  }
}

p {
  font-size: 18px !important;
  line-height: 28px;
}
</style>